#content-header {
  background: #fff;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 130px;
  overflow: hidden;
}

#content-main {
  background: #fff;
  position: fixed;
  top: 130px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto; 
}

#content-footer {
  position: fixed;
  bottom: 0;
  height: 35px;
  width: 100%;
}

.padding {
  padding: 15px;
}

.correction-itemCell {

  min-height: 54px;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #0078d4;
  display: flex;
}

.correction-itemCell:hover {
  background: #EEE;
}

.correction-itemImage {
  flex: 0 0 32px;
}

.correction-itemContent {
  overflow: hidden;
  flex: 1;
}

.correction-itemName {
  overflow: hidden;
  text-overflow: ellipsis;
}
